/* You can add global styles to this file, and also import other style files */
@tailwind base;
@layer base {
    h1 {
      @apply text-5xl;
      @apply font-bold;
    }
    h2 {
      @apply text-xl;
      @apply font-bold;
    }
    h3 {
      @apply text-lg;
    }
    a {
      @apply text-blue-600 underline;
    }
  }
@tailwind components;
@tailwind utilities;

.bg-gray-uti {
    background:#e9e9e9;
}
.bg-blue-uti {
    background:#263b94;
}
.hover\:bg-blue-uti:hover {
  background:#263b94;
}

.bg-blue-active-uti {
    background:#91caff;
}
.text-blue-uti {
    color: #263b94;
}

.text-blue-active-uti {
  color: #91caff;
}

.hover\:text-blue-uti:hover {
  background:#91caff;
}
.text-black-uti {
    color: #1e1e1e;
}



html, nav, h1, h2, h3, h4 ,h5, h6, button, .ibm-plex-sans-thai {
  font-family: "IBM Plex Sans Thai", sans-serif;
}
p, .ibm-plex-sans-thai-looped, .job-announcement-edit{
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
}

.ibm-plex-sans-thai-looped-thin {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex-sans-thai-looped-extralight {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex-sans-thai-looped-light {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex-sans-thai-looped-regular {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-sans-thai-looped-medium {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex-sans-thai-looped-semibold {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex-sans-thai-looped-bold {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.ibm-plex-sans-thai-thin {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex-sans-thai-extralight {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex-sans-thai-light {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex-sans-thai-regular {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-sans-thai-medium {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex-sans-thai-semibold {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex-sans-thai-bold {
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-weight: 700;
  font-style: normal;
}
